import React, { useState } from "react"
import { graphql, PageProps } from "gatsby"

import { useAnalytics } from "../hooks"

import {
  FilterOption,
  Header,
  Layout,
  Resource,
  SEO,
  SignOffStillLooking,
  SectionsHandler,
} from "../components"
import { pageType } from "../types"

type Props = PageProps & {
  data: pageType & {
    allContentfulResourceGroups: {
      edges: Array<{
        node: {
          name: string
          contentful_id: string
          resources: Array<{
            resource: {
              resource: string
            }
            type: string
            url: string
            contentful_id: string
          }>
        }
      }>
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()

  const [sidebarVisibileMobile, setSidebarVisibilityMobile] = useState(false)
  const resources = data.allContentfulResourceGroups.edges
  const url = process.env.GATSBY_SITE_URL + "/resources/"
  analytics.track(["User", "Page View"], { page: "/resources/" })

  return (
    <Layout>
      <SEO
        title={data.contentfulPages.seo.title}
        description={data.contentfulPages.seo.description.description}
        image={data.contentfulPages.seo.socialImage?.file?.url}
        url={url}
      />
      <Header
        heading={data.contentfulPages.heading}
        headerIntroduction={data.contentfulPages.headerIntroduction}
        headerImage={data.contentfulPages.headerImage}
      />
      <main className="backgroundGreyLightSuper">
        <section className="wrapper wrapperSidebarLayout">
          <aside
            className={`wrapperSidebar${sidebarVisibileMobile ? " open" : ""}`}
          >
            <div className="sidebar">
              <span className="sidebarHeading">Quick access</span>
              <section className="sidebarItems">
                {resources.map(({ node }) => (
                  <FilterOption
                    key={node.contentful_id}
                    value={node.name}
                    scroll
                    closeMobileWrapper={() => setSidebarVisibilityMobile(false)}
                  />
                ))}
              </section>
            </div>
            <button
              className="sidebarControl"
              onClick={() => setSidebarVisibilityMobile(!sidebarVisibileMobile)}
            >
              <span className="accessibleText">Show/hide filters</span>
            </button>
            <span className="fill"></span>
          </aside>
          <section className="filteredContent">
            <span className="filterCount">
              {`${resources.length} resource group`}
            </span>
            {resources.map(({ node }) => (
              <section
                key={node.contentful_id}
                className="resourceGroup"
                id={node.name}
              >
                <h2>{node.name}</h2>
                {node.resources.map((resource) => (
                  <Resource
                    key={resource.contentful_id}
                    name={resource.resource.resource}
                    url={resource.url}
                    type={resource.type}
                  />
                ))}
              </section>
            ))}
          </section>
        </section>
      </main>
      <SectionsHandler sections={data.contentfulPages.sections} />
      <SignOffStillLooking />
    </Layout>
  )
}

export const pageQuery = graphql`
  query allResources {
    allContentfulResourceGroups(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          contentful_id
          resources {
            resource {
              resource
            }
            type
            url
            contentful_id
          }
        }
      }
    }
    contentfulPages(page: { eq: "Resources" }) {
      contentful_id
      heading
      headerIntroduction {
        raw
      }
      headerImage {
        gatsbyImageData
        title
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
      sections {
        ... on ContentfulPageSectionsCourseGroups {
          contentful_id
          name
          __typename
          courseGroups {
            ... on ContentfulCourseGroups {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                title
                gatsbyImageData
              }
              contentful_id
              slug
            }
            ... on ContentfulCourseTopics {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                gatsbyImageData
                title
              }
              contentful_id
              slug
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsFaQs {
          __typename
          contentful_id
          heading
          faQs {
            heading
            content {
              raw
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsHowItWorks {
          __typename
          contentful_id
          heading
          steps {
            contentful_id
            description {
              description
            }
            heading
            icon {
              file {
                url
              }
              gatsbyImageData
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStats {
          __typename
          contentful_id
          stats {
            name
            description {
              description
            }
            number
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsTextWithImage {
          __typename
          id
          contentful_id
          image {
            gatsbyImageData
            title
            url
          }
          video {
            url
            title
          }
          imageAlignment
          heading
          content {
            raw
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStrap {
          __typename
          id
          contentful_id
          buttonSupportingText
          description
          name
          buttonText
          buttonUrl
          backgroundColour
        }
      }
    }
  }
`
